import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../config/axiosInterceptor";
import Header from "./common/Header";
import { Controller, useForm } from "react-hook-form";
import { onBoardStoreData } from "../store/onboardStore";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { isEmpty, set } from "lodash";
import CheckboxTree from "react-checkbox-tree";
import { ImLocation2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-select-search/style.css";
import {
  addClinics,
  getRefBrandData,
  getSpecialities,
} from "../config/apiEndpoints";
import { DocList } from "../config/pathConstant";
import days from "../config/weekdays.json";
import { timeSlots } from "../store/timeSlotStore";
import Loader from "./common/Loader";
import SimpleMap from "./SimpleMap";
import Timing from "./Timing";
import { IoMdTime } from "react-icons/io";
import Modal from "react-modal"; // You can use this or any other modal library/component
import { MdOutlineClose } from "react-icons/md";

const geoLocationKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const EditDocDetails = () => {
  const { id } = useParams();
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const doctorData = onBoardStoreData((state) => state?.doctorData);
  const [loading, setLoading] = useState(false);
  const [geoLocation, setGeoLocation] = useState({ lat: 0, lng: 0 }); // Default geolocation state

  const timeSlot = timeSlots((state) => state.timeSlot);
  const [copyToPopup, setCopyToPopup] = useState(false);
  const [formattedSlots, setFormattedSlots] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const selectedSpeciality = onBoardStoreData(
    (state) => state.selectedSpeciality
  );
  const setSelectedSpeciality = onBoardStoreData(
    (state) => state.setSelectedSpeciality
  );
  // const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const specialitiesData = timeSlots((state) => state.specialitiesData);
  const setSpecialitiesData = timeSlots((state) => state.setSpecialitiesData);
  const navigate = useNavigate();
  const setDoctorTag = onBoardStoreData((state) => state.setDoctorTag);
  const setFormLang = onBoardStoreData((state) => state.setFormLang);
  const formLang = onBoardStoreData((state) => state.formLang);
  const [imagePreview, setImagePreview] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [slot, setSlot] = useState([]);
  const [openTiming, setOpenTiming] = useState(false);
  const ecardId = timeSlots((state) => state.ecardId);
  const setSpecialityData = timeSlots((state) => state.setSpecialityData);
  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const setDoctorImg = onBoardStoreData((state) => state.setDoctorImg);
  const campaignId = timeSlots((state) => state.campaignId);
  const [selectedLanguage, setSelectedLanguage] = useState(formLang);
  const [isOpen, setIsOpen] = useState(false);
  const specialityLangArray = onBoardStoreData(
    (state) => state.specialityLangArray
  );
  const setSpecialityLangArray = onBoardStoreData(
    (state) => state.setSpecialityLangArray
  );
  const festiveCardType = onBoardStoreData((state) => state.festiveCardType);

  const langRef = useRef(null);

  const [selectedBrand, setSelectedBrand] = useState([]);

  const brandServiceDrop = onBoardStoreData((state) => state.brandServiceDrop);
  const setBrandServiceDrop = onBoardStoreData(
    (state) => state.setBrandServiceDrop
  );
  const setBrandSpecData = onBoardStoreData((state) => state.setBrandSpecData);
  const brandSpeciality = onBoardStoreData((state) => state.brandSpeciality);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionError, setSelectionError] = useState("");

  const repSaveNo = localStorage.getItem("rep_mobile_number");

  useEffect(() => {
    const getDocDetails = async () => {
      try {
        const request = await getRequest({
          url: `${addClinics}/${id}`,
        });

        const response = await request.data;

        if (response?.status) {
          console.log(response, "dddddd");
          setDoctorData(response?.response);
        }
      } catch (error) {
        if (error?.status === 401) {
          localStorage.removeItem("token", "");
        }
        console.error("error", error);
      }
    };

    getDocDetails();
  }, [id]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
          console.log(position.coords.latitude, "latitude");
          console.log(position.coords.longitude, "longitude");
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (timeSlot) {
      let temp = [];
      Object.keys(timeSlot).map((day) => {
        let individualSlot = timeSlot[day];
        temp = [
          ...temp,
          {
            ...individualSlot,
            day: day,
            in_clinic: Object.values(individualSlot.in_clinic),
            virtual: Object.values(individualSlot.virtual),
          },
        ];
      });

      let formatted = temp.filter((slot) => {
        if (slot.in_clinic.length == 0 && slot.virtual.length == 0) {
          return false;
        } else {
          return true;
        }
      });
      if (formatted) setSlot(formatted);
    }
  }, [timeSlot]);

  const handleShowMap = () => {
    setShowMap(true);
  };

  useEffect(() => {
    let temp = [];
    Object.keys(timeSlot).map((day) => {
      let individualSlot = timeSlot[day];
      temp = [
        ...temp,
        {
          ...individualSlot,
          day: day,
          in_clinic: Object.values(individualSlot.in_clinic),
          virtual: Object.values(individualSlot.virtual),
        },
      ];
    });

    setFormattedSlots(
      temp.filter((slot) => {
        if (slot.in_clinic.length == 0 && slot.virtual.length == 0) {
          return false;
        } else {
          return true;
        }
      })
    );
  }, [timeSlot]);

  useEffect(() => {
    const getSpeciality = async () => {
      try {
        setLoading(true);
        const request = await getRequest({
          url: getSpecialities,
          auth: false,
          authToken: "",
          withoutLogin: true,
        });
        const response = await request?.data;
        if (response?.status) {
          if (!isEmpty(response?.response)) {
            setLoading(false);
            setSpecialityLangArray(response?.response?.dropdown);
            const options = response?.response?.dropdown?.map((item) => ({
              value: item?.key,
              label: item?.label,
            }));
            const specialityDataArr = response?.response?.specialities?.map(
              (item) => ({
                name: item?.name,
                color: item?.color,
                icon: item?.icon,
                hor_background_image: item?.hor_background_image,
                ver_background_image: item?.ver_background_image,
                card2_background_image: item?.card2_background_image,
                card3_background_image: item?.card3_background_image,
                card4_background_image: item?.card4_background_image,
                hindi_name: item?.hindi_name,
                marathi_name: item?.marathi_name,
              })
            );
            setSpecialities(options);
            setSpecialitiesData(specialityDataArr);
          } else {
            setLoading(false);
            setSpecialities([]);
          }
        }
      } catch (error) {
        console.log(error?.status);
        if (error?.status === 401) {
          localStorage.removeItem("token", "");
        }
        setLoading(false);
        console.log("error", error);
      }
    };
    getSpeciality();
  }, []);

  useEffect(() => {
    if (doctorData && selectedLanguage) {
      if (selectedLanguage === "english") {
        setSelectedSpeciality({
          label: doctorData?.speciality_name,
          value: doctorData?.speciality,
        });
      } else if (selectedLanguage === "hindi") {
        setSelectedSpeciality({
          label: doctorData?.speciality_hindi_name,
          value: doctorData?.speciality_hindi_name,
        });
      } else if (selectedLanguage === "marathi") {
        setSelectedSpeciality({
          label: doctorData?.speciality_marathi_name,
          value: doctorData?.speciality_marathi_name,
        });
      }
    }
  }, [doctorData, selectedLanguage]);

  useEffect(() => {
    if (!isEmpty(specialitiesData) && selectedSpeciality) {
      let foundItem;
      if (selectedLanguage === "hindi") {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&ENGLISH&&& ----", item);
          return item.hindi_name === selectedSpeciality.label;
        });
      } else if (selectedLanguage === "marathi") {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&HINDI&&& ----", item);
          return item.marathi_name === selectedSpeciality.label;
        });
      } else {
        foundItem = specialitiesData.find((item) => {
          // console.log(selectedSpeciality, "---- &&&MARATHI&&& ----", item);
          return item.name === selectedSpeciality.label;
        });
      }

      if (foundItem) {
        console.log(foundItem, "single item");
        setSpecialityData(foundItem); // Set the found item directly
      } else {
        setSpecialityData(null); // Handle case where no item is found
      }
    }
  }, [selectedSpeciality, selectedLanguage]);

  const cleanValue = (value) =>
    value === "null" || value === "undefined" ? null : value;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: doctorData?.first_name ?? "",
      lastName: doctorData?.last_name ?? "",
      speciality: doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,

            value: doctorData?.speciality,
          }
        : null,
      clinicNumber: doctorData?.clinic_number ?? null,
      clinicName: doctorData?.clinic_name ?? null,
      address: doctorData?.address ?? null,
      lat: doctorData?.lat ?? null,
      long: doctorData?.long ?? null,
      degree: doctorData?.professional_degree ?? null,
      photo: doctorData?.profile_photo ?? null,
      email: doctorData?.email ?? null,
      mciNumber: doctorData?.doctor_mci ?? null,
      doctorNumber: doctorData?.doctor_number ?? null,
      whatsappNumber: doctorData?.whatsapp_number ?? null,
      repNumber: repSaveNo
        ? repSaveNo
        : doctorData?.payload
        ? doctorData?.payload[0]?.rep_mobile_number
        : null,
    },
  });

  useEffect(() => {
    setValue("firstName", doctorData?.first_name);
    setValue("lastName", doctorData?.last_name);
    setValue(
      "speciality",
      doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,
            value: doctorData?.speciality,
          }
        : null
    );
    setValue("clinicNumber", doctorData?.clinic_number);
    setValue("clinicName", doctorData?.clinic_name);
    setValue("address", doctorData?.address);
    setValue("lat", doctorData?.lat);
    setValue("long", doctorData?.long);
    setValue("degree", doctorData?.professional_degree);
    setValue("photo", doctorData?.profile_photo ?? null);
    setValue("email", doctorData?.email ?? "");
    setValue("mciNumber", cleanValue(doctorData?.mci_number));
    setValue("doctorNumber", cleanValue(doctorData?.doctor_number));
    setValue("whatsappNumber", cleanValue(doctorData?.whatsapp_number));
    setValue(
      "repNumber",
      repSaveNo
        ? repSaveNo
        : doctorData?.payload && doctorData?.payload[0]?.rep_mobile_number
    );
    // setValue("brands", doctorData?.payload[0]?.doctor_brands ?? null);
    setSelectedSpeciality(
      doctorData?.speciality
        ? {
            label:
              selectedLanguage === "hindi"
                ? doctorData?.speciality_hindi_name
                : selectedLanguage === "marathi"
                ? doctorData?.speciality_marathi_name
                : doctorData?.speciality_name,
            value: doctorData?.speciality,
          }
        : null
    );
  }, [doctorData]);

  const handleCloseMap = () => {
    setShowMap(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const doctorBrands = selectedBrand.map((brand) => ({
      brand_item_code: brand.value,
      brand_item_name: brand.label,
    }));

    const payload = [
      {
        rep_mobile_number: data?.repNumber,
        doctor_brands: doctorBrands,
      },
    ];

    const formData = new FormData();
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append(
      "speciality",
      selectedSpeciality?.value
        ? selectedSpeciality?.value
        : doctorData?.speciality
        ? doctorData?.speciality
        : ""
    );
    formData.append("clinic_name", data?.clinicName ?? "");
    formData.append("email", data?.email ?? "");
    formData.append("clinic_number", data?.clinicNumber ?? "");
    formData.append("address", data?.address ?? "");
    formData.append("lat", geoLocation?.lat ?? "");
    formData.append("long", geoLocation?.lng ?? "");
    formData.append("professional_degree", data?.degree ?? "");
    formData.append("timing", slot ? JSON.stringify(slot) : []);
    formData.append("profile_photo", doctorImg ? doctorImg : "");
    formData.append("mci_number", data?.mciNumber ?? "");
    formData.append("doctor_number", data?.doctorNumber ?? "");
    formData.append("whatsapp_number", data?.whatsappNumber ?? "");
    campaignId === 3 && formData.append("payload", JSON.stringify(payload));

    try {
      const request = await putRequest({
        url: `${addClinics}/${id}`,
        body: formData,
      });
      const response = request?.data;
      if (response?.status) {
        toast.success("Details Updated Successfully");
        setLoading(false);
        navigate(DocList);
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
      console.error("Error submitting form", error);
    }
  };

  const handleSpecChange = (selectedOption) => {
    setSelectedSpeciality(selectedOption);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDoctorImg(file);
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  useEffect(() => {
    const brandService = async () => {
      try {
        const request = await getRequest({
          url: getRefBrandData,
          auth: true,
          authToken: "",
          withoutLogin: true,
        });

        const response = await request.data;

        if (response.status) {
          const options = response?.response?.brand_dropdown?.map((item) => ({
            value: item?.key,
            label: item?.label,
          }));
          setBrandServiceDrop(options);

          const filteredSpecialities = response?.response?.specialities?.filter(
            (speciality) => speciality.name === brandSpeciality
          );

          const brandOpj = {
            speciality: filteredSpecialities?.[0] || null,
            icon_quality: response?.response?.icon_quality,
            icon_footprint: response?.response?.icon_footprint,
            icon_logo: response?.response?.icon_logo,
            border_icon: response?.response?.border_icon,
          };

          setBrandSpecData(brandOpj);
        } else {
        }
      } catch (error) {
        console.log(error?.status);
        if (error?.status === 401) {
          localStorage.removeItem("token", "");
        }
        console.log("error", error);
      }
    };
    if (campaignId == 3) {
      brandService();
    }
  }, [campaignId]);

  useEffect(() => {
    if (doctorData?.profile_photo) {
      setImagePreview(doctorData?.profile_photo ?? null);
    }
  }, [doctorData]);

  console.log("load clinic page");

  useEffect(() => {
    const loadLocation = (latitude, longitude) => {
      console.log("Received latitude: " + latitude);
      console.log("Received longitude: " + longitude);
    };
    loadLocation();
  }, []);

  const languages = [
    { value: "english", label: "English" },
    { value: "hindi", label: "Hindi" },
    { value: "marathi", label: "Marathi" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (value) => {
    setSelectedLanguage(value);
    setIsOpen(false);
    setDoctorTag(value === "english" ? "Dr." : "डॉ.");
    setFormLang(value);
    setSelectedSpeciality({});
    setValue("speciality", null);
  };

  const openGooglePermission = () => {
    if (window?.android_bridge) {
      window?.android_bridge?.deviceLocationPermission(true);
    } else if (window?.webkit) {
      window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
        functionName: "deviceLocationPermission",
        data: true,
      });
    }
  };

  useEffect(() => {
    openGooglePermission();
  }, []);

  const languagesArray = {
    english: {
      editHeader: "Edit doctor details",
      fName: "First Name",
      fNamePlaceholder: "Enter First Name",
      lName: "Last Name",
      lNamePlaceholder: "Enter Last Name",
      speciality: "Speciality",
      specialityPlaceholder: "Select Speciality",
      email: "Email (optional)",
      emailPlaceholder: "Enter Email",
      clinicName: "Clinic Name",
      doctorNumber: "Doctor Number",
      doctorNoPlaceholder: "Enter Doctor Number",
      mciNumber: "Doctor MCI",
      mciNoPlaceholder: "Enter MCI",
      whatsappNumber: "Doctor WhatsApp Number",
      whatsappNoPlaceholder: "Enter WhatsApp Number",
      clinicNaPlaceholder: "Enter Clinic Name",
      clinicNumber: "Clinic Number",
      location: "Location",
      useMapLocation: "Use Map Location",
      clinicNoPlaceholder: "Enter Clinic Number",
      address: "Address",
      addressPlaceHolder: "Enter Address",
      professionalDegree: "Professional Degree",
      profPlaceholder: "Enter Professional Degree",
      Timings: "Timings",
      doctorPhoto: "Doctor Photo",
      updateCard: "Update E-Card",
      updateFestiveCard: "Update Festive Card",
    },
    hindi: {
      editHeader: "डॉक्टर का विवरण संपादित करें",
      fName: "पहला नाम",
      fNamePlaceholder: "पहला नाम दर्ज करें",
      lName: "अंतिम नाम",
      lNamePlaceholder: "अंतिम नाम दर्ज करें",
      speciality: "विशेषता",
      specialityPlaceholder: "विशेषता दर्ज करें",
      email: "ईमेल (वैकल्पिक)",
      emailPlaceholder: "ईमेल दर्ज करें",
      doctorNumber: "डॉक्टर नंबर",
      doctorNoPlaceholder: "डॉक्टर नंबर दर्ज करें",
      mciNumber: "डॉक्टर एमसीआई",
      mciNoPlaceholder: "एमसीआई दर्ज करें",
      whatsappNumber: "डॉक्टर व्हाट्सएप नंबर",
      whatsappNoPlaceholder: "व्हाट्सएप नंबर दर्ज करें",
      clinicName: "क्लिनिक का नाम",
      clinicNaPlaceholder: "क्लिनिक का नाम दर्ज करें",
      clinicNumber: "क्लिनिक नंबर",
      location: "स्थान",
      useMapLocation: "मानचित्र स्थान का उपयोग करें",
      clinicNoPlaceholder: "क्लिनिक नंबर दर्ज करें",
      address: "पता",
      addressPlaceHolder: "पता दर्ज करें",
      professionalDegree: "पेशेवर डिग्री",
      profPlaceholder: "पेशेवर डिग्री दर्ज करें",
      Timings: "समय",
      doctorPhoto: "डॉक्टर की फोटो",
      updateCard: "ई-कार्ड अपडेट करें",
      updateFestiveCard: "फेस्टिव कार्ड अपडेट करें",
    },
    marathi: {
      editHeader: "डॉक्टरचा तपशील संपादित करा",
      fName: "पहिले नाव",
      fNamePlaceholder: "पहिले नाव प्रविष्ट करा",
      lName: "शेवटचे नाव",
      lNamePlaceholder: "शेवटचे नाव प्रविष्ट करा",
      speciality: "विशेषता",
      specialityPlaceholder: "विशेषता प्रविष्ट करा",
      email: "ईमेल (ऐच्छिक)",
      emailPlaceholder: "ईमेल प्रविष्ट करा",
      doctorNumber: "डॉक्टर क्रमांक",
      doctorNoPlaceholder: "डॉक्टर क्रमांक प्रविष्ट करा",
      mciNumber: "डॉक्टर एमसीआय",
      mciNoPlaceholder: "एमसीआय प्रविष्ट करा",
      whatsappNumber: "डॉक्टर व्हॉट्सअ‍ॅप नंबर",
      whatsappNoPlaceholder: "व्हॉट्सअ‍ॅप नंबर प्रविष्ट करा",
      clinicName: "क्लिनिकचे नाव",
      clinicNaPlaceholder: "क्लिनिकचे नाव प्रविष्ट करा",
      clinicNumber: "क्लिनिक क्रमांक",
      location: "स्थान",
      useMapLocation: "नकाशाचे स्थान वापरा",
      clinicNoPlaceholder: "क्लिनिक क्रमांक प्रविष्ट करा",
      address: "पत्ता",
      addressPlaceHolder: "पत्ता प्रविष्ट करा",
      professionalDegree: "व्यावसायिक पदवी",
      profPlaceholder: "व्यावसायिक पदवी प्रविष्ट करा",
      Timings: "वेळापत्रक",
      doctorPhoto: "डॉक्टरचा फोटो",
      updateCard: "ई-कार्ड अद्यतनित करा",
      updateFestiveCard: "सणाचा कार्ड अद्यतनित करा",
    },
  };

  const handleBrandSelection = (selectedOptions) => {
    // Check if selected options are 4 or less
    if (selectedOptions.length <= 4) {
      setSelectedBrand(selectedOptions); // Update the selected brand list
      handleSpecChange(selectedOptions); // Update the form
      setSelectionError(""); // Clear any previous error
    } else {
      setSelectionError("You can select a maximum of 4 brands."); // Set error message
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    let updatedSpecialities;
    if (specialityLangArray) {
      updatedSpecialities = specialityLangArray.map((item) => ({
        value: item?.key,
        label:
          selectedLanguage === "english"
            ? item?.label
            : selectedLanguage === "hindi"
            ? item?.hindi_label
            : selectedLanguage === "marathi"
            ? item?.marathi_label
            : null,
      }));
    }
    setSpecialities(updatedSpecialities);
  }, [selectedLanguage, specialityLangArray]);

  const langClickOutside = (event) => {
    if (langRef.current && !langRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", langClickOutside);
    return () => {
      document.removeEventListener("mousedown", langClickOutside);
    };
  }, []);

  return (
    <>
      <Header isBack={true} />
      <div className="p-3 pt-4 mt-14 h-[calc(100vh-56px)]">
        {campaignId !== 3 && (
          <div className="flex justify-between items-center">
            <div>
              <h2
                className={`text-xl ${
                  selectedLanguage === "english" ? "font-semibold" : ""
                } mb-2`}
              >
                {languagesArray?.[selectedLanguage]?.editHeader}
              </h2>
            </div>
            {festiveCardType !== "gif" && campaignId !== 2 ? (
              <div>
                <div className="relative inline-block text-left">
                  <div>
                    <label
                      htmlFor="language"
                      className="block text-[10px] font-medium text-gray-700 mb-1"
                    >
                      Choose a language:
                    </label>
                    <div>
                      <button
                        type="button"
                        onClick={toggleDropdown}
                        className="w-full flex justify-between items-center bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        {
                          languages.find(
                            (lang) => lang.value === selectedLanguage
                          )?.label
                        }
                        <svg
                          className="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 10l5 5 5-5H7z"
                          />
                        </svg>
                      </button>
                      {isOpen && (
                        <div
                          ref={langRef}
                          className="absolute mt-1 w-full bg-white rounded-md shadow-lg z-10"
                        >
                          {languages.map((lang) => (
                            <button
                              key={lang.value}
                              onClick={() => handleSelect(lang.value)}
                              className={`block w-full text-left px-4 py-2 text-[10px] ${
                                selectedLanguage === lang.value
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900 hover:bg-blue-100"
                              }`}
                            >
                              {lang.label}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 pb-3">
          {/* Doctor F-Name */}
          <div className="mb-3">
            <label
              htmlFor="firstName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.fName}
              <span className="text-red-500"> *</span>
            </label>
            <div
              className={`${
                errors.lastName ? "border-red-500" : "border-gray-300"
              } flex items-center px-2 border rounded-lg mt-1.5`}
            >
              <p>{selectedLanguage === "english" ? "Dr." : "डॉ."}</p>
              <input
                type="text"
                id="firstName"
                className="focus-within:outline-none rounded-lg text-sm p-2 w-full"
                placeholder={
                  languagesArray?.[selectedLanguage]?.fNamePlaceholder
                }
                {...register("firstName", {
                  required: "* First Name is required",
                  pattern: {
                    value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                    message: "* Enter valid First Name (only characters)",
                  },
                })}
              />
            </div>
            {errors.firstName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.firstName.message}
              </p>
            )}
          </div>
          {/* Doctor L-Name */}
          <div className="mb-3">
            <label
              htmlFor="lastName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.lName}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="lastName"
              className={`${
                errors.lastName ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.lNamePlaceholder}
              {...register("lastName", {
                required: "* Last Name is required",
                pattern: {
                  value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                  message: "* Enter valid Last Name (only characters)",
                },
              })}
            />
            {errors.lastName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.lastName.message}
              </p>
            )}
          </div>
          {/* Doctor speciality */}
          {campaignId !== 3 ? (
            <>
              <div className="mb-3">
                <label
                  htmlFor="speciality"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.speciality}
                  <span className="text-red-500"> *</span>
                </label>

                <Controller
                  name="speciality"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={specialities}
                      placeholder={
                        languagesArray?.[selectedLanguage]
                          ?.specialityPlaceholder || "Select Speciality"
                      }
                      className={`${
                        errors.speciality ? "border-red-500" : "border-gray-300"
                      } focus-within:outline-none rounded-lg w-full mt-1.5 text-sm`}
                      onChange={(selectedOption) => {
                        handleSpecChange(selectedOption);
                        field.onChange(selectedOption);
                      }}
                      // If the value is not set, ensure `null` is passed so the placeholder shows
                      value={field.value || null}
                    />
                  )}
                />

                {errors.speciality && (
                  <p className="text-xs font-medium text-red-500 pt-2">
                    * Speciality is required
                  </p>
                )}
              </div>
              {/* Doctor Email */}
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.email}
                </label>
                <input
                  type="email"
                  id="email"
                  className={`${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.emailPlaceholder
                  }
                  {...register("email", {
                    required: false,
                  })}
                />
                {errors.email && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.email.message}
                  </p>
                )}
              </div>
              {/* Doctor mci Number*/}
              <div className="mb-3">
                <label
                  htmlFor="mciNumber"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.mciNumber}
                  <span> (optional)</span>
                </label>
                <input
                  type="text"
                  id="mciNumber"
                  className={`${
                    errors.mciNumber ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.mciNoPlaceholder
                  }
                  {...register("mciNumber", {
                    required: false,
                  })}
                />
                {errors.clinicName && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.clinicName.message}
                  </p>
                )}
              </div>
              {/* Doctor Clinic Name */}
              <div className="mb-3">
                <label
                  htmlFor="clinicName"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.clinicName}
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  type="text"
                  id="clinicName"
                  className={`${
                    errors.clinicName ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.clinicNaPlaceholder
                  }
                  {...register("clinicName", {
                    required: "* Clinic Name is required",
                  })}
                />
                {errors.clinicName && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.clinicName.message}
                  </p>
                )}
              </div>
              {/* Doctor Clinic Number */}
              <div className="mb-3">
                <label
                  htmlFor="clinicNumber"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.clinicNumber}
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  type="number"
                  id="clinicNumber"
                  className={`${
                    errors.clinicNumber ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
                  }
                  {...register("clinicNumber", {
                    required: "* Clinic Number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "* Enter a valid number",
                    },
                    minLength: {
                      value: 10,
                      message: " *Please enter correct numbers",
                    },
                    maxLength: {
                      value: 10,
                      message: " *Please enter Maximum 10 numbers",
                    },
                  })}
                />
                {errors.clinicNumber && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.clinicNumber.message}
                  </p>
                )}
              </div>
              {/* Doctor Number */}
              <div className="mb-3">
                <label
                  htmlFor="doctorNumber"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.doctorNumber}
                  <span className=""> (optional)</span>
                </label>
                <input
                  type="number"
                  id="doctorNumber"
                  className={`${
                    errors.doctorNumber ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
                  }
                  {...register("doctorNumber", {
                    required: false,
                  })}
                />
                {errors.doctorNumber && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.doctorNumber.message}
                  </p>
                )}
              </div>
              {/* Doctor WhatsApp Number */}
              <div className="mb-3">
                <label
                  htmlFor="whatsappNumber"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.whatsappNumber}
                  <span className=""> (optional)</span>
                </label>
                <input
                  type="number"
                  id="whatsappNumber"
                  className={`${
                    errors.whatsappNumber ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
                  }
                  {...register("whatsappNumber", {
                    required: false,
                  })}
                />
                {errors.whatsappNumber && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.whatsappNumber.message}
                  </p>
                )}
              </div>
              {/* Location */}
              <label
                htmlFor="location"
                className={`text-xs text-gray-400 ${
                  selectedLanguage === "english" ? "font-semibold" : ""
                }`}
              >
                {languagesArray?.[selectedLanguage]?.location}
              </label>
              <button
                type="button"
                id="location"
                className={`flex p-2 bg-blue-500 text-white shadow-gray-500 shadow-md ${
                  selectedLanguage === "english" ? "font-semibold" : ""
                } rounded mt-2 text-sm`}
                onClick={() => {
                  handleShowMap();
                  openGooglePermission();
                }}
              >
                {languagesArray?.[selectedLanguage]?.useMapLocation}{" "}
                <ImLocation2 className="mt-1 ml-1" />
              </button>
              {showMap && (
                <SimpleMap
                  setGeoLocation={setGeoLocation}
                  geoLocation={geoLocation}
                  setShowMap={setShowMap}
                  setValue={setValue}
                  onCloseMap={handleCloseMap}
                />
              )}
              {Object.keys(geoLocation).length > 0 && (
                <Map geoLocation={geoLocation} />
              )}
              {/* Doctor Address */}
              <div className="my-3">
                <label
                  htmlFor="address"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.address}
                  <span className="text-red-500"> *</span>
                </label>

                <textarea
                  // onClick={handleScreenScroll}
                  id="address"
                  rows={4}
                  className={`${
                    errors.address ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.addressPlaceHolder
                  }
                  {...register("address", {
                    required: "* Address is required",
                  })}
                />
                {errors.address && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.address.message}
                  </p>
                )}
              </div>{" "}
              {/* Professional Degree */}
              <div className="mb-3">
                <label
                  htmlFor="degree"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  {languagesArray?.[selectedLanguage]?.professionalDegree}
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  type="text"
                  id="degree"
                  className={`${
                    errors.degree ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5 uppercase`}
                  placeholder={
                    languagesArray?.[selectedLanguage]?.profPlaceholder
                  }
                  {...register("degree", {
                    required: "* Professional Degree is required",
                  })}
                />
                {errors.degree && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.degree.message}
                  </p>
                )}
              </div>
              {/* Timings */}
              {campaignId == 1 ? (
                <div className="mb-3">
                  <label
                    htmlFor="timings"
                    className={`text-xs text-gray-400 ${
                      selectedLanguage === "english" ? "font-semibold" : ""
                    }`}
                  >
                    {languagesArray?.[selectedLanguage]?.Timings}
                  </label>
                  <button
                    onClick={() => setOpenTiming(true)}
                    className="flex p-2 bg-blue-500 text-white shadow-gray-500 shadow-md font-semibold rounded mt-2 text-sm items-center gap-1"
                    type="button"
                  >
                    Add Timing
                    <IoMdTime className="text-lg" />
                  </button>
                  {openTiming && (
                    <Timing days={days} setPopup={setOpenTiming} />
                  )}
                </div>
              ) : (
                ""
              )}
              {/* Doctor Photo */}
              {ecardId == 3 && campaignId == 1 ? (
                <div className="mb-3">
                  <label
                    htmlFor="photo"
                    className={`text-xs text-gray-400 ${
                      selectedLanguage === "english" ? "font-semibold" : ""
                    }`}
                  >
                    Doctor Photo
                  </label>
                  <input
                    type="file"
                    id="photo"
                    className="focus-within:outline-none text-sm p-2 border border-gray-300 rounded-lg w-full mt-1.5"
                    {...register("photo", {
                      required: false,
                    })}
                    onChange={handleImageChange}
                  />
                  {imagePreview && (
                    <div className="mt-3">
                      <img
                        src={imagePreview}
                        alt="Selected"
                        className="h-48 w-48 object-contain rounded mt-1.5"
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {/* Brand Heading */}
              <div className="mb-3 relative inline-block text-left w-full">
                <label
                  htmlFor="brands"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  Select Brands
                  <span className="text-red-500"> *</span>
                </label>

                {/* Trigger to open the popup */}
                <button
                  type="button"
                  onClick={toggleModal}
                  className="border border-gray-300 focus:outline-none rounded-lg w-full mt-1.5 text-sm px-4 py-2"
                >
                  Select Brands
                </button>

                {/* Modal/Popup for brand selection */}
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={toggleModal}
                  className="bg-white rounded-lg overflow-scroll mt-14 h-[calc(100vh-64px)] shadow-lg p-3 max-w-lg mx-auto"
                  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                >
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="text-lg font-semibold">Select Brands</h2>
                    <p onClick={() => setIsModalOpen(false)}>
                      <MdOutlineClose fontSize={24} color="#3b82f6" />
                    </p>
                  </div>
                  <p className="text-gray-500 text-sm mb-4 italic">
                    You can select a maximum of 4 brands.
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {brandServiceDrop &&
                      brandServiceDrop.map((brand) => {
                        const isSelected = selectedBrand.some(
                          (b) => b.value === brand.value
                        );
                        const isMaxSelectionReached = selectedBrand.length >= 4;

                        return (
                          <button
                            key={brand.value}
                            className={`px-2 py-1 border rounded-lg text-[12px] ${
                              isSelected
                                ? "bg-blue-200"
                                : isMaxSelectionReached
                                ? "text-gray-300 "
                                : "bg-gray-100"
                            }`}
                            disabled={!isSelected && isMaxSelectionReached}
                            onClick={() => {
                              const newSelection = isSelected
                                ? selectedBrand.filter(
                                    (b) => b.value !== brand.value
                                  )
                                : [...selectedBrand, brand];

                              handleBrandSelection(newSelection);
                            }}
                          >
                            {brand.label}
                          </button>
                        );
                      })}
                  </div>
                  <button
                    onClick={toggleModal}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg"
                  >
                    Save Changes
                  </button>
                </Modal>

                {/* Error message */}
                {errors.brands && (
                  <p className="text-xs font-medium text-red-500 pt-2">
                    * Brands are required
                  </p>
                )}
                {selectionError && (
                  <p className="text-xs font-medium text-red-500 pt-2">
                    * {selectionError}
                  </p>
                )}

                {/* Display selected brands similar to the second image */}
                {selectedBrand.length > 0 && (
                  <div className="mt-2">
                    <p className="text-xs text-gray-400">Selected Brands:</p>
                    <ul className="flex flex-wrap gap-2 mt-1">
                      {selectedBrand.map((brand) => (
                        <li
                          key={brand.value}
                          className="text-sm bg-blue-200 text-blue-800 px-3 py-1 rounded-lg"
                        >
                          {brand.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="repNumber"
                  className={`text-xs text-gray-400 ${
                    selectedLanguage === "english" ? "font-semibold" : ""
                  }`}
                >
                  My Number
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  type="number"
                  id="repNumber"
                  className={`${
                    errors.repNumber ? "border-red-500" : "border-gray-300"
                  } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
                  placeholder="My Number"
                  {...register("repNumber", {
                    required: "* Clinic Number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "* Enter a valid number",
                    },
                    minLength: {
                      value: 10,
                      message: " *Please enter correct numbers",
                    },
                    maxLength: {
                      value: 10,
                      message: " *Please enter Maximum 10 numbers",
                    },
                  })}
                />
                {errors.repNumber && (
                  <p className="text-xs font-medium text-red-500 pt-2 ">
                    {errors.repNumber.message}
                  </p>
                )}
              </div>
            </>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white px-3 py-2 rounded-lg mt-4"
          >
            {campaignId == 1
              ? languagesArray?.[selectedLanguage]?.updateCard
              : campaignId == 2
              ? languagesArray?.[selectedLanguage]?.updateFestiveCard
              : "Update Brand Card"}
          </button>
        </form>
        {copyToPopup && (
          <CopyPopup setPopup={setCopyToPopup} copyToPopup={copyToPopup} />
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};

export default EditDocDetails;

const CopyPopup = ({ setPopup, copyToPopup }) => {
  const [selected, setSelected] = useState([]);
  const [nodes, setNodes] = useState([]);
  const timeSlot = timeSlots((state) => state.timeSlot);
  const setTimeSlot = timeSlots((state) => state.setTimeSlot);

  const days = [
    {
      value: "Monday",
      label: "Monday",
    },
    {
      value: "Tuesday",
      label: "Tuesday",
    },
    {
      value: "Wednesday",
      label: "Wednesday",
    },
    {
      value: "Thursday",
      label: "Thursday",
    },
    {
      value: "Friday",
      label: "Friday",
    },
    {
      value: "Saturday",
      label: "Saturday",
    },
    {
      value: "Sunday",
      label: "Sunday",
    },
  ];

  useEffect(() => {
    setNodes(days.filter((d) => d.value !== copyToPopup.title));
  }, [copyToPopup]);

  const copyTime = () => {
    let selectedTime = timeSlot[copyToPopup.title][copyToPopup.clinicType];
    let temp = timeSlot;
    selected.forEach((d) => {
      temp = {
        ...temp,
        [d]: {
          ...temp[d],
          [copyToPopup.clinicType]: selectedTime,
        },
      };
    });
    setTimeSlot(temp);
    setPopup(false);
  };

  return (
    <div
      onClick={() => setPopup(false)}
      className="fixed top-0 left-0 w-screen z-[99] backdrop-blur-sm backdrop-brightness-75"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        className="fixed p-4 bottom-0 w-screen z-20 bg-white rounded-t-xl overflow-hidden"
      >
        {" "}
        <p className="text-lg text-gray-900 font-semibold">
          Select days to copy time slots from {copyToPopup.title}
        </p>
        <div>
          <CheckboxTree
            nodes={nodes}
            checked={selected}
            onCheck={(checked) => {
              setSelected(checked);
            }}
            className="NodeLeaf"
          />
          <div onClick={selected.length > 0 ? copyTime : void 0}>
            <buttom>
              <p className="text-base text-white font-medium">Copy</p>
            </buttom>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

function Map({ geoLocation }) {
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${geoLocation?.lat},${geoLocation?.lng}&zoom=19&size=600x300&maptype=roadmap&markers=color:red%7C${geoLocation?.lat},${geoLocation?.lng}&key=${geoLocationKey}`;
  return (
    <div className="mt-4 border border-gray-300 rounded-md">
      <img height={200} src={mapUrl} alt="Map" />
    </div>
  );
}
