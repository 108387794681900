import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import { toast } from "react-toastify";
import { getRequest } from "../config/axiosInterceptor";
import { campaigns } from "../config/apiEndpoints";
import Loader from "./common/Loader";
import { useForm, Controller } from "react-hook-form";
import CampaignCard from "./CampaignCard";
import { isEmpty } from "lodash";

const Campaigns = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        const request = await getRequest({
          url: `${campaigns}`,
        });
        const response = request?.data;
        if (response?.status) {
          setCampaignData(response?.response?.data);
        }
        setLoading(false);
      } catch (err) {
        if (err?.status === 401) {
          localStorage.removeItem("token", "");
        }
        setLoading(false);
        toast.error("Something went wrong!");
        console.log(err);
      }
    };
    fetchCampaigns();
  }, []);

  return (
    <div>
      <Header isBack={true} />
      <div className="mt-16 px-4 py-2">
        <h4 className="mb-4 text-lg">Select Campaigns</h4>
        <div className="grid grid-cols-2 gap-4">
          {!isEmpty(campaignData) &&
            campaignData?.map((item, index) => {
              return <CampaignCard item={item} key={index} />;
            })}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Campaigns;
