import React from "react";
import { Link } from "react-router-dom";
import { eCardList } from "../config/pathConstant";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";

const CampaignCard = ({ item, key }) => {
  const setCampaignId = timeSlots((state) => state.setCampaignId);
  const setType = onBoardStoreData((state) => state.setType);

  return (
    <Link to={`${eCardList}/${item?.id}`} key={key}>
      <div
        onClick={() => {
          setCampaignId(item.id);
          setType(item?.slug ?? "clinic");
        }}
        className="rounded-md bg-blue-100 p-2 flex flex-col justify-center items-center"
      >
        <img src={item?.camp_icon} className="h-20 w-20" alt={item?.title} />
        <div className="font-semibold text-sm">{item?.title}</div>
      </div>
    </Link>
  );
};

export default CampaignCard;
