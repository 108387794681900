import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { onBoardStoreData } from "../../store/onboardStore";
import { toast } from "react-toastify";
import Overflow from "./Overflow";
import {
  addClinics,
  campaigns,
  getMemberTemplate,
} from "../../config/apiEndpoints";
import { postRequest } from "../../config/axiosInterceptor";
import Loader from "./Loader";
import { isEmpty } from "lodash";
import { timeSlots } from "../../store/timeSlotStore";

const SharePopup = ({ setShowSharePopup, setShowDrSearch }) => {
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const clearDoctorData = onBoardStoreData((state) => state.clearDoctorData);
  const repDetails = onBoardStoreData((state) => state.repDetails);
  const setRepDetails = onBoardStoreData((state) => state.setRepDetails);
  console.log(repDetails, "rep detail");
  const campaignId = timeSlots((state) => state.campaignId);
  const ecardId = timeSlots((state) => state.ecardId);
  const journalDetail = onBoardStoreData((state) => state.journalDetail);
  const [personalizedMessage, setPersonalizedMessage] = useState("");
  const [repMobile, setRepMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const localRepMobile = localStorage.getItem("rep_mobile_number");
  console.log(localRepMobile, "localRepMobile");

  useEffect(() => {
    if (journalDetail) {
      const message = `
Dear Dr. ${doctorData?.first_name} ${doctorData?.last_name},

${journalDetail?.title}

${journalDetail?.description}

${journalDetail?.link}

Regards,
${repDetails?.fname} ${repDetails?.lname}
${
  repDetails?.mobile_number
    ? repDetails?.mobile_number
    : repMobile
    ? repMobile
    : ""
}
      `.trim();
      setPersonalizedMessage(message);
    }
  }, [journalDetail]);

  console.error("ecardId", journalDetail, "sssss", ecardId);

  useEffect(() => {
    Overflow.stop();
    return () => {
      Overflow.start();
    };
  }, []);

  const onClinic = async () => {
    setShowDrSearch(false);
    if (repDetails?.mobile_number) {
      return share();
    }
    setLoading(true);
    const payload = [
      {
        rep_mobile_number: repMobile,
        doctor_brands: [],
      },
    ];
    const formData = new FormData();
    formData.append("first_name", doctorData?.first_name ?? "");
    formData.append("last_name", doctorData?.last_name ?? "");
    formData.append("speciality", "");
    formData.append("clinic_name", "");
    formData.append("email", "");
    formData.append("clinic_number", "");
    formData.append("address", "");
    formData.append("lat", "");
    formData.append("long", "");
    formData.append("professional_degree", "");
    formData.append("timing", "");
    formData.append("profile_photo", "");
    formData.append("mci_number", "");
    formData.append("doctor_number", "");
    formData.append("whatsapp_number", "");
    formData.append("payload", JSON.stringify(payload));
    try {
      const request = await postRequest({
        url: addClinics,
        body: formData,
      });
      const response = request?.data;
      if (response?.status) {
        console.log(true);
        setLoading(false);
        share();
        setShowSharePopup(false);
        setShowDrSearch(false);
        localStorage.setItem(
          "rep_mobile_number",
          response?.response?.payload[0]?.rep_mobile_number
        );
        if (!isEmpty(response?.response?.payload) && !isEmpty(repDetails)) {
          setRepDetails({
            ...repDetails, // Spread the existing properties of repDetails
            mobile_number: response.response.payload[0].rep_mobile_number, // Set mobile_number directly
          });
        }
        setRepMobile(response.response.payload[0].rep_mobile_number ?? "");
        setShowSharePopup(false);
        setShowDrSearch(false);
        share();
      } else {
        setLoading(false);
        setShowDrSearch(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      setShowDrSearch(false);
      toast.error("Something went wrong!");
      console.error("Error submitting form", error);
    }
  };

  const sendMemberTemplate = async (id) => {
    setLoading(true);
    try {
      const request = await postRequest({
        url: getMemberTemplate,
        body: {
          doctor_id: doctorData?.id,
          template_id: ecardId,
          campaign_id: campaignId,
        },
      });
      const response = await request?.data;
      console.log("resss", response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const share = () => {
    sendMemberTemplate();
    setShowSharePopup(false);
    if (journalDetail && (repMobile || repDetails?.mobile_number)) {
      if (window?.android_bridge || window?.webkit) {
        let urlLink = `${journalDetail?.link}\n\nRegards,\n${
          repDetails?.fname
        } ${repDetails?.lname}\n${
          repDetails?.mobile_number
            ? repDetails?.mobile_number
            : repMobile
            ? repMobile
            : ""
        }`;
        let message = journalDetail?.title ?? "";
        let img = journalDetail?.image ?? "";
        let desc = journalDetail?.description ?? "";
        message = `Dear Dr. ${doctorData?.first_name} ${doctorData?.last_name}\n\n*${message}*\n\n${desc}`;
        if (window?.android_bridge) {
          window?.android_bridge?.share(urlLink, "", message, img);
        }
      }
    } else {
      toast.error("Something went wrong while sharing");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50">
      <div className="bg-white w-11/12 max-w-lg p-4 rounded-lg shadow-lg relative">
        <div className="flex justify-between items-center">
          <h2 className="text-base font-semibold text-gray-800">
            Personalized Share Preview
          </h2>
          <MdClose
            className="text-xl text-gray-600 cursor-pointer"
            onClick={() => {
              setShowSharePopup(false);
              setShowDrSearch(false);
              clearDoctorData();
            }}
          />
        </div>
        <hr className="my-3" />
        {isEmpty(repDetails?.mobile_number) && (
          <>
            <label htmlFor="repNumber" className="text-sm text-black mt-4">
              Rep Mobile Number
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="number"
              id="repNumber"
              className={`
            focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder="Rep Mobile Number"
              value={repMobile}
              onChange={(e) => setRepMobile(e.target.value)}
            />
          </>
        )}
        <div className="my-2">
          <textarea
            id="message"
            rows="15"
            className="w-full p-3 border border-gray-300 text-sm rounded-md focus:ring-2 focus:ring-blue-400"
            placeholder="Write your personalized message here"
            value={personalizedMessage}
            disabled
          />
        </div>
        <div className="flex justify-end mt-2">
          <p
            className="inline-flex items-center bg-green-500 text-white text-sm py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
            onClick={onClinic}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
              className="w-5 h-5 mr-2"
            />
            Send via WhatsApp
          </p>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default SharePopup;
