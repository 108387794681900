import React, { useEffect, useRef, useState } from "react";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import { isEmpty } from "lodash";

const ECardPreview = ({ doctorData, setHeight, brandSpecData }) => {
  const [cardHtml, setCardHtml] = useState("");
  const formLang = onBoardStoreData((state) => state.formLang);
  const cardPreviewHtml = onBoardStoreData((state) => state.cardPreviewHtml);
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;
  const specialityData = timeSlots((state) => state.specialityData);
  const doctorTag = onBoardStoreData((state) => state.doctorTag);
  const timing = doctorData?.timing;
  const repDetails = onBoardStoreData((state) => state.repDetails);

  const divRef = useRef(null);
  useEffect(() => {
    getDivHeight();
  }, [cardHtml]);

  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };

  function formatClinicTimings(timing, specialityData, formLang) {
    const parsedTimings = JSON.parse(timing);
    const timeSlots = {};
    const dayOrder = [
      { english: "Mon", hindi: "सोम", marathi: "सोम" },
      { english: "Tue", hindi: "मंगल", marathi: "मंगळ" },
      { english: "Wed", hindi: "बुध", marathi: "बुध" },
      { english: "Thu", hindi: "गुरु", marathi: "गुरु" },
      { english: "Fri", hindi: "शुक्र", marathi: "शुक्र" },
      { english: "Sat", hindi: "शनि", marathi: "शनि" },
      { english: "Sun", hindi: "रवि", marathi: "रवि" },
    ];
    // Create time slots mapping days to the timings
    if (parsedTimings && Array.isArray(parsedTimings)) {
      parsedTimings.forEach(({ in_clinic, day }) => {
        if (Array.isArray(in_clinic)) {
          in_clinic.forEach(({ from_time, to_time }) => {
            const timeRange = `${convertTo12Hour(
              from_time
            )} - ${convertTo12Hour(to_time)}`;
            if (!timeSlots[timeRange]) {
              timeSlots[timeRange] = [];
            }
            // Map the day to the selected language
            const dayObject = dayOrder.find(
              (dayItem) => dayItem.english === day.slice(0, 3)
            );
            const localizedDay = dayObject
              ? dayObject[formLang]
              : day.slice(0, 3);
            timeSlots[timeRange].push(localizedDay);
          });
        } else {
          console.warn(`Invalid or missing in_clinic data for day: ${day}`);
        }
      });
    } else {
      console.error("parsedTimings is not an array or is null:", parsedTimings);
    }

    let result = "";
    // Process time slots for consecutive day ranges
    for (const [timeRange, days] of Object.entries(timeSlots)) {
      const uniqueDays = [...new Set(days)]; // Remove duplicates
      const sortedDays = uniqueDays.sort(
        (a, b) =>
          dayOrder.findIndex((item) => item[formLang] === a) -
          dayOrder.findIndex((item) => item[formLang] === b)
      ); // Ensure days are in the correct order
      let groupedDays = [];
      let currentGroup = [sortedDays[0]];
      for (let i = 1; i < sortedDays.length; i++) {
        const prevDayIndex = dayOrder.findIndex(
          (item) => item[formLang] === sortedDays[i - 1]
        );
        const currDayIndex = dayOrder.findIndex(
          (item) => item[formLang] === sortedDays[i]
        );
        // If the current day is consecutive to the previous day
        if (currDayIndex === prevDayIndex + 1) {
          currentGroup.push(sortedDays[i]);
        } else {
          // Push the grouped days as a range or individual
          groupedDays.push(formatDayGroup(currentGroup));
          currentGroup = [sortedDays[i]];
        }
      }
      // Push the final group of days
      groupedDays.push(formatDayGroup(currentGroup));
      const formattedDays = groupedDays.join(", ");
      result += `<p className="" style={{color: ${specialityData?.color}}}>
                    <span className="font-semibold mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px; font-weight: 600">${formattedDays}:</span>
                    <span className="mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px;">${timeRange}</span>
                 </p>\n`;
    }
    return result.trim();
  }
  // Helper function to format consecutive day ranges
  function formatDayGroup(days) {
    if (days.length === 1) {
      return days[0].slice(0, 3); // Single day (e.g., "Mon")
    }
    return `${days[0].slice(0, 3)} - ${days[days.length - 1].slice(0, 3)}`; // Consecutive days (e.g., "Mon - Wed")
  }

  function convertTo12Hour(time) {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${String(hour).padStart(2, "0")}:${minute} ${ampm}`;
  }

  const convertBrandSerList = () => {
    let result = "";

    if (!isEmpty(doctorData?.payload)) {
      if (
        doctorData?.payload[0]?.doctor_brands &&
        doctorData?.payload[0]?.doctor_brands.length > 0
      ) {
        result += `<ul class="swanky-and-moo-moo-regular">\n`;
        doctorData?.payload[0]?.doctor_brands.forEach((brand, index) => {
          result += `  <li key="${index}">${brand.brand_item_name}</li>\n`;
        });
        result += "</ul>";
      } else {
        result += "<ul>\n</ul>";
      }
    }

    return result.trim();
  };

  const langSpec = (doctorData, formLang) => {
    return formLang == "english"
      ? doctorData?.speciality_name
      : doctorData?.speciality_hindi_name;
  };

  useEffect(() => {
    if (!cardPreviewHtml) return;

    // Function to replace placeholders in the HTML
    const replacePlaceholders = (html, replacements) => {
      let updatedHtml = html;
      Object.entries(replacements).forEach(([placeholder, value]) => {
        const safeValue = value || ""; // Fallback for undefined/null
        updatedHtml = updatedHtml.replaceAll(`{{${placeholder}}}`, safeValue);
      });
      return updatedHtml;
    };

    const phoneNumberConverter = (num, formLang) => {
      const hindiDigits = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

      // Ensure the input number is valid
      if (num === null || num === undefined) {
        console.error("Error: Invalid input number (null or undefined).");
        return "Invalid input number";
      }

      const convertToLanguage = (digits) => {
        return num
          .toString()
          .split("")
          .map((digit) => (/\d/.test(digit) ? digits[digit] : digit))
          .join("");
      };

      if (formLang === "hindi" || formLang === "marathi") {
        return convertToLanguage(hindiDigits);
      } else {
        return num;
      }
    };

    const doctorName = `${doctorTag} ${doctorData?.first_name || ""} ${
      doctorData?.last_name || ""
    }`.trim();
    const replacements = {
      doctorName,
      spouseName: doctorData?.spouse_name
        ? ` & ${doctorData?.spouse_name}`
        : "",
      specialtyName: langSpec(doctorData, formLang),
      specialityIcon: specialityData?.icon,
      specialtyColor: specialityData?.color,
      professionalDegree: doctorData?.professional_degree,
      clinicName: doctorData?.clinic_name,
      clinicNumber: phoneNumberConverter(doctorData?.clinic_number, formLang),
      doctorEmail: doctorData?.email,
      clinicAddress: doctorData?.address,
      doctorImage:
        doctorData?.profile_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/images_uploaded/dr-pediatrician-profile.jpg",
      qrCode: `<div id="qrcode"></div>`,
      backgroundSpecialityImg: specialityData?.hor_background_image,
      backgroundSpecVerImg: specialityData?.ver_background_image,
      specialityData2: specialityData?.card2_background_image,
      specialityData3: specialityData?.card3_background_image,
      specialityData4: specialityData?.card4_background_image,
      specialityColor: specialityData?.color,
      specialityColor2: specialityData?.color2,
      clinicTiming: formatClinicTimings(timing, specialityData, formLang),
      brandIconLogo: brandSpecData?.icon_logo,
      brandSpecBgImg: brandSpecData?.speciality?.background_image,
      brandSpecDarkCol: brandSpecData?.speciality?.dark_color,
      brandSpecLightCol: brandSpecData?.speciality?.light_color,
      brandSpecHeading: brandSpecData?.speciality?.heading,
      specBorderIcon: specialityData?.border_icon,
      specBorderIcon2: specialityData?.border_icon2,
      brandBorderIcon: brandSpecData?.border_icon,
      brandSpecSubHeading: brandSpecData?.speciality?.sub_heading,
      brandIconQuality: brandSpecData?.icon_quality,
      brandSerList: convertBrandSerList(),
      repName: doctorData?.rep_name,
      repNumber: repDetails
        ? repDetails?.mobile_number
        : doctorData?.payload
        ? doctorData?.payload[0]?.rep_mobile_number
        : "",
      brandSpecPaperLess: brandSpecData?.speciality?.paperless_image,
      chemistShopImg:
        doctorData?.shop_photo ??
        "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-1.webp",
      chemistName: doctorData?.first_name + " " + doctorData?.last_name,
      chemistDesignation: doctorData?.designation ?? "Pharmacist",
      chemistDrugLicense: doctorData?.drug_license ?? "",
      doctorWhatsappNo: phoneNumberConverter(
        doctorData?.whatsapp_number,
        formLang
      ),
      quailtyImg:
        formLang === "marathi"
          ? `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+Dangler.png`
          : `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/Sagehealth+DanglerHindi.png`,
      quailtyImgCard:
        formLang == "marathi"
          ? `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist_border_new_marathi.png`
          : `https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist_border_new_hindi.png`,
    };

    const updatedHtml = replacePlaceholders(cardPreviewHtml, replacements);

    setCardHtml(updatedHtml);
  }, [cardPreviewHtml, doctorData]);

  useEffect(() => {
    const checkAndUpdateTimingDiv = () => {
      const timingDiv = document.getElementById("timing-div");
      const timings = document.getElementById("timing");

      const emailDiv = document.getElementById("email-div");
      const email = document.getElementById("email");

      const whatsappDiv = document.getElementById("whatsapp-div");
      const whatsappNo = document.getElementById("whatsapp-no");

      if (timingDiv) {
        if (timings && timings.childNodes.length > 0) {
          timingDiv.style.display = "flex";
        } else {
          timingDiv.style.display = "none";
        }
      }

      if (emailDiv) {
        if (emailDiv && email.textContent.trim() !== "") {
          emailDiv.style.display = "flex";
        } else {
          emailDiv.style.display = "none";
        }
      }

      if (whatsappDiv) {
        if (whatsappDiv && whatsappNo.textContent.trim() !== "") {
          whatsappDiv.style.display = "flex";
        } else {
          whatsappDiv.style.display = "none";
        }
      }
    };

    const timeoutId = setTimeout(checkAndUpdateTimingDiv, 0); // Delay to ensure rendering
    return () => clearTimeout(timeoutId); // Cleanup
  }, [cardPreviewHtml, cardHtml]);

  useEffect(() => {
    if (cardHtml) {
      // Ensure the QRCode constructor is available
      if (typeof window !== "undefined" && window.QRCode) {
        const qrcodeContainer = document.getElementById("qrcode");
        if (qrcodeContainer) {
          // Clear any existing QR code to avoid duplication
          qrcodeContainer.innerHTML = "";

          // Generate the QR Code
          new window.QRCode(qrcodeContainer, {
            text: googleMapsUrl,
            width: 55,
            height: 55,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: window.QRCode.CorrectLevel.H,
          });
        } else {
          console.error("QR code container not found.");
        }
      } else {
        console.error("QRCode is not defined.");
      }
    }
  }, [cardHtml, googleMapsUrl]);

  if (!cardHtml) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div ref={divRef}>
      <div dangerouslySetInnerHTML={{ __html: cardHtml }} />
    </div>
  );
};

export default ECardPreview;
