import { motion } from "framer-motion";
import { isEmpty, set } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { Controller, useForm } from "react-hook-form";
import { ImLocation2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-select-search/style.css";
import { toast } from "react-toastify";
import {
  addClinics,
  getMemberTemplate,
  getRefBrandData,
  getSpecialities,
} from "../config/apiEndpoints";
import { getRequest, postRequest } from "../config/axiosInterceptor";
import { generateECard } from "../config/pathConstant";
import days from "../config/weekdays.json";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import Header from "./common/Header";
import Loader from "./common/Loader";
import SimpleMap from "./SimpleMap";
import Timing from "./Timing";
import { IoMdTime } from "react-icons/io";
import Modal from "react-modal"; // You can use this or any other modal library/component
import { MdClose, MdOutlineClose } from "react-icons/md";

const geoLocationKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const Clinic = () => {
  const setSelectedSpeciality = onBoardStoreData(
    (state) => state.setSelectedSpeciality
  );
  // const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const setDoctorTag = onBoardStoreData((state) => state.setDoctorTag);
  const doctorTag = onBoardStoreData((state) => state.doctorTag);
  const setFormLang = onBoardStoreData((state) => state.setFormLang);
  const formLang = onBoardStoreData((state) => state.formLang);
  const [imagePreview, setImagePreview] = useState(null);
  const [geoLocation, setGeoLocation] = useState({ lat: 0, lng: 0 }); // Default geolocation state
  const [showMap, setShowMap] = useState(false);
  const ecardId = timeSlots((state) => state.ecardId);
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const setDoctorImg = onBoardStoreData((state) => state.setDoctorImg);
  const campaignId = timeSlots((state) => state.campaignId);
  const [selectedLanguage, setSelectedLanguage] = useState(formLang);
  const [isOpen, setIsOpen] = useState(false);
  const festiveCardType = onBoardStoreData((state) => state.festiveCardType);

  const langRef = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
          console.log(position.coords.latitude, "latitude");
          console.log(position.coords.longitude, "longitude");
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleShowMap = () => {
    setShowMap(true);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: doctorData?.first_name ?? "",
      lastName: doctorData?.last_name ?? "",
      email: doctorData?.email ?? null,
      clinicNumber: doctorData?.clinic_number ?? null,
      address: doctorData?.address ?? null,
      lat: doctorData?.lat ?? null,
      long: doctorData?.long ?? null,
      degree: doctorData?.professional_degree ?? null,
    },
  });

  useEffect(() => {
    setValue("firstName", doctorData?.first_name);
    setValue("lastName", doctorData?.last_name);
    setValue("email", doctorData?.email ?? "");
    setValue("clinicNumber", doctorData?.clinic_number);
    setValue("address", doctorData?.address);
    setValue("lat", doctorData?.lat);
    setValue("long", doctorData?.long);
    setValue("degree", doctorData?.professional_degree);
  }, [doctorData]);

  const handleCloseMap = () => {
    setShowMap(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", data?.firstName);
    formData.append("last_name", data?.lastName);
    formData.append("email", data?.email ?? "");
    formData.append("clinic_number", data?.clinicNumber ?? "");
    formData.append("address", data?.address ?? "");
    formData.append("lat", geoLocation?.lat ?? "");
    formData.append("long", geoLocation?.lng ?? "");
    formData.append("professional_degree", data?.degree ?? "");
    formData.append("timing", []);
    try {
      const request = await postRequest({
        url: addClinics,
        body: formData,
      });
      const response = request?.data;
      if (response?.status) {
        setDoctorData(response?.response);
        const id = response?.response?.id;
        if (id) {
          sendMemberTemplate(id);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
      console.error("Error submitting form", error);
    }
  };

  const sendMemberTemplate = async (id) => {
    setLoading(true);
    try {
      const request = await postRequest({
        url: getMemberTemplate,
        body: {
          doctor_id: id,
          template_id: ecardId,
          campaign_id: campaignId,
        },
      });
      const response = await request?.data;
      if (response?.status) {
        navigate(generateECard);
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleSpecChange = (selectedOption) => {
    setSelectedSpeciality(selectedOption);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDoctorImg(file);
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  const openGooglePermission = () => {
    if (window?.android_bridge) {
      window?.android_bridge?.deviceLocationPermission(true);
    } else if (window?.webkit) {
      window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
        functionName: "deviceLocationPermission",
        data: true,
      });
    }
  };

  useEffect(() => {
    openGooglePermission();
  }, []);

  useEffect(() => {
    const loadLocation = (latitude, longitude) => {
      console.log("Received latitude: " + latitude);
      console.log("Received longitude: " + longitude);
    };
    loadLocation();
  }, []);

  const languages = [
    { value: "english", label: "English" },
    { value: "hindi", label: "Hindi" },
    { value: "marathi", label: "Marathi" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (value) => {
    setSelectedLanguage(value);
    setIsOpen(false);
    setFormLang(value);
  };

  const languagesArray = {
    english: {
      mainHeader: "Create your cards",
      simMainHeader: "by filling out the form below",
      fName: "First Name",
      fNamePlaceholder: "Enter First Name",
      lName: "Last Name",
      lNamePlaceholder: "Enter Last Name",
      email: "Email",
      emailPlaceholder: "Enter Email",
      location: "Location",
      useMapLocation: "Use Map Location",
      clinicNumber: "Mobile Number",
      clinicNoPlaceholder: "Enter Mobile Number",
      address: "Address",
      addressPlaceHolder: "Enter Address",
      professionalDegree: "Designation/ Degree",
      profPlaceholder: "Enter Designation/ Degree",
      updateCard: "Update E-Card",
    },
    hindi: {
      mainHeader: "अपने कार्ड बनाएं",
      simMainHeader: "नीचे दिए गए फॉर्म को भरकर",
      fName: "पहला नाम",
      fNamePlaceholder: "पहला नाम दर्ज करें",
      lName: "अंतिम नाम",
      lNamePlaceholder: "अंतिम नाम दर्ज करें",
      email: "ईमेल",
      emailPlaceholder: "ईमेल दर्ज करें",
      location: "स्थान",
      useMapLocation: "मानचित्र स्थान का उपयोग करें",
      clinicNumber: "मोबाइल नंबर",
      clinicNoPlaceholder: "मोबाइल नंबर दर्ज करें",
      address: "पता",
      addressPlaceHolder: "पता दर्ज करें",
      professionalDegree: "पद/ डिग्री",
      profPlaceholder: "पद/ डिग्री दर्ज करें",
      updateCard: "ई-कार्ड अपडेट करें",
    },
    marathi: {
      mainHeader: "तुमचे कार्ड तयार करा",
      simMainHeader: "खाली दिलेल्या फॉर्म भरून",
      fName: "पहिले नाव",
      fNamePlaceholder: "पहिले नाव प्रविष्ट करा",
      lName: "शेवटचे नाव",
      lNamePlaceholder: "शेवटचे नाव प्रविष्ट करा",
      email: "ईमेल",
      emailPlaceholder: "ईमेल प्रविष्ट करा",
      location: "स्थान",
      useMapLocation: "नकाशाचे स्थान वापरा",
      clinicNumber: "मोबाइल नंबर",
      clinicNoPlaceholder: "मोबाइल नंबर प्रविष्ट करा",
      address: "पत्ता",
      addressPlaceHolder: "पत्ता प्रविष्ट करा",
      professionalDegree: "पद/ डिग्री",
      profPlaceholder: "पद/ डिग्री प्रविष्ट करा",
      updateCard: "ई-कार्ड अद्यतनित करा",
    },
  };

  const langClickOutside = (event) => {
    if (langRef.current && !langRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", langClickOutside);
    return () => {
      document.removeEventListener("mousedown", langClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <Header isBack={true} showSearch={true} />
      <div className="mt-16 p-4">
        {campaignId !== 3 && campaignId !== 4 && (
          <div className="flex justify-between items-center">
            <div>
              <h2
                className={`text-xl ${
                  selectedLanguage === "english" ? "font-semibold" : ""
                } mb-2`}
              >
                {languagesArray?.[selectedLanguage]?.mainHeader}
              </h2>
              <h2
                className={`text-xl ${
                  selectedLanguage === "english" ? "font-semibold" : ""
                }`}
              >
                {languagesArray?.[selectedLanguage]?.simMainHeader}
              </h2>
            </div>
            {festiveCardType !== "gif" && campaignId !== 2 ? (
              <div>
                <div className="relative inline-block text-left">
                  <div>
                    <label
                      htmlFor="language"
                      className="block text-[10px] font-medium text-gray-700 mb-1"
                    >
                      Choose a language:
                    </label>
                    <div>
                      <button
                        type="button"
                        onClick={toggleDropdown}
                        className="w-full flex justify-between items-center bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        {
                          languages.find(
                            (lang) => lang.value === selectedLanguage
                          )?.label
                        }
                        <svg
                          className="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 10l5 5 5-5H7z"
                          />
                        </svg>
                      </button>
                      {isOpen && (
                        <div
                          ref={langRef}
                          className="absolute mt-1 w-full bg-white rounded-md shadow-lg z-10"
                        >
                          {languages.map((lang) => (
                            <button
                              key={lang.value}
                              onClick={() => handleSelect(lang.value)}
                              className={`block w-full text-left px-4 py-2 text-[10px] ${
                                selectedLanguage === lang.value
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900 hover:bg-blue-100"
                              }`}
                            >
                              {lang.label}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
          {/* Doctor F-Name */}
          <div className="mb-3">
            <label
              htmlFor="firstName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.fName}
              <span className="text-red-500"> *</span>
            </label>
            <div
              className={`${
                errors.firstName ? "border-red-500" : "border-gray-300"
              } flex items-center px-2 border rounded-lg mt-1.5`}
            >
              <select
                className="bg-white border-none outline-none text-sm mr-2"
                value={doctorTag} // Controlled by Zustand state
                onChange={(e) => {
                  setDoctorTag(e.target.value);
                }} // Update Zustand state
              >
                <option value={selectedLanguage === "english" ? "Dr." : "डॉ."}>
                  {selectedLanguage === "english" ? "Dr." : "डॉ."}
                </option>
                <option
                  value={selectedLanguage === "english" ? "Mr." : "श्री."}
                >
                  {selectedLanguage === "english" ? "Mr." : "श्री."}
                </option>
                <option
                  value={selectedLanguage === "english" ? "Ms." : "सुश्री."}
                >
                  {selectedLanguage === "english" ? "Ms." : "सुश्री."}
                </option>
                <option
                  value={selectedLanguage === "english" ? "Mrs." : "श्रीमती."}
                >
                  {selectedLanguage === "english" ? "Mrs." : "श्रीमती."}
                </option>
                <option value="">
                  {selectedLanguage === "english" ? "--" : "--"}
                </option>
              </select>
              <input
                type="text"
                id="firstName"
                className="focus-within:outline-none rounded-lg text-sm p-2 w-full"
                placeholder={
                  languagesArray?.[selectedLanguage]?.fNamePlaceholder
                }
                {...register("firstName", {
                  required: "* First Name is required",
                  pattern: {
                    value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                    message: "* Enter valid First Name (only characters)",
                  },
                })}
              />
            </div>
            {errors.title && (
              <p className="text-xs font-medium text-red-500 pt-2">
                {errors.title.message}
              </p>
            )}
            {errors.firstName && (
              <p className="text-xs font-medium text-red-500 pt-2">
                {errors.firstName.message}
              </p>
            )}
          </div>
          {/* Doctor L-Name */}
          <div className="mb-3">
            <label
              htmlFor="lastName"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.lName}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="lastName"
              className={`${
                errors.lastName ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.lNamePlaceholder}
              {...register("lastName", {
                required: "* Last Name is required",
                pattern: {
                  value: /^[a-zA-Z\u0900-\u097F\s.]+$/,
                  message: "* Enter valid Last Name (only characters)",
                },
              })}
            />
            {errors.lastName && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.lastName.message}
              </p>
            )}
          </div>
          {/* Doctor Email */}
          <div className="mb-3">
            <label
              htmlFor="email"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.email}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="email"
              id="email"
              className={`${
                errors.email ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={languagesArray?.[selectedLanguage]?.emailPlaceholder}
              {...register("email", {
                required: "* Email is required",
              })}
            />
            {errors.email && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.email.message}
              </p>
            )}
          </div>
          {/* Doctor Number */}
          <div className="mb-3">
            <label
              htmlFor="clinicNumber"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.clinicNumber}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="number"
              id="clinicNumber"
              className={`${
                errors.clinicNumber ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.clinicNoPlaceholder
              }
              {...register("clinicNumber", {
                required: "* Clinic Number is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "* Enter a valid number",
                },
                minLength: {
                  value: 10,
                  message: " *Please enter correct numbers",
                },
                maxLength: {
                  value: 10,
                  message: " *Please enter Maximum 10 numbers",
                },
              })}
            />
            {errors.clinicNumber && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.clinicNumber.message}
              </p>
            )}
          </div>
          {/* Location */}
          <label
            htmlFor="location"
            className={`text-xs text-gray-400 ${
              selectedLanguage === "english" ? "font-semibold" : ""
            }`}
          >
            {languagesArray?.[selectedLanguage]?.location}
          </label>
          <button
            type="button"
            id="location"
            className={`flex p-2 bg-blue-500 text-white shadow-gray-500 shadow-md ${
              selectedLanguage === "english" ? "font-semibold" : ""
            } rounded mt-2 text-sm`}
            onClick={() => {
              handleShowMap();
              openGooglePermission();
            }}
          >
            {languagesArray?.[selectedLanguage]?.useMapLocation}{" "}
            <ImLocation2 className="mt-1 ml-1" />
          </button>
          {showMap && (
            <SimpleMap
              setGeoLocation={setGeoLocation}
              geoLocation={geoLocation}
              setShowMap={setShowMap}
              setValue={setValue}
              onCloseMap={handleCloseMap}
            />
          )}
          {Object.keys(geoLocation).length > 0 && (
            <Map geoLocation={geoLocation} />
          )}
          {/* Doctor Address */}
          <div className="my-3">
            <label
              htmlFor="address"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.address}
              <span className="text-red-500"> *</span>
            </label>

            <textarea
              // onClick={handleScreenScroll}
              id="address"
              rows={4}
              className={`${
                errors.address ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5`}
              placeholder={
                languagesArray?.[selectedLanguage]?.addressPlaceHolder
              }
              {...register("address", {
                required: "* Address is required",
              })}
            />
            {errors.address && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.address.message}
              </p>
            )}
          </div>{" "}
          {/* Professional Degree */}
          <div className="mb-3">
            <label
              htmlFor="degree"
              className={`text-xs text-gray-400 ${
                selectedLanguage === "english" ? "font-semibold" : ""
              }`}
            >
              {languagesArray?.[selectedLanguage]?.professionalDegree}
              <span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              id="degree"
              className={`${
                errors.degree ? "border-red-500" : "border-gray-300"
              } focus-within:outline-none text-sm p-2 border rounded-lg w-full mt-1.5 uppercase`}
              placeholder={languagesArray?.[selectedLanguage]?.profPlaceholder}
              {...register("degree", {
                required: "* Professional Degree is required",
              })}
            />
            {errors.degree && (
              <p className="text-xs font-medium text-red-500 pt-2 ">
                {errors.degree.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-3 py-2 rounded-lg mt-4"
          >
            Create card
          </button>
        </form>

        {loading && <Loader />}
      </div>
    </div>
  );
};

export default Clinic;

function Map({ geoLocation }) {
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${geoLocation?.lat},${geoLocation?.lng}&zoom=19&size=600x300&maptype=roadmap&markers=color:red%7C${geoLocation?.lat},${geoLocation?.lng}&key=${geoLocationKey}`;
  return (
    <div className="mt-4 border border-gray-300 rounded-md">
      <img height={200} src={mapUrl} alt="Map" />
    </div>
  );
}
