import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCampaignPostData, journalContent } from "../config/apiEndpoints";
import { getRequest } from "../config/axiosInterceptor";
import { chemistForm, clinic, hqForm } from "../config/pathConstant";
import Header from "./common/Header";
import Loader from "./common/Loader";
import { timeSlots } from "../store/timeSlotStore";
import { onBoardStoreData } from "../store/onboardStore";
import Journal1 from "./journals/Journal1";
import SharePopup from "./common/SharePopup";

const ECardList = () => {
  const navigate = useNavigate();
  const [posterData, setPosterData] = useState([]);
  const [journalData, setJournalData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);
  const setECardId = timeSlots((state) => state.setECardId);
  const { id } = useParams();
  const clearSlots = timeSlots((state) => state.clearSlots);
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const campaignId = timeSlots((state) => state.campaignId);
  const setFormLang = onBoardStoreData((state) => state.setFormLang);
  const setCardPreviewHtml = onBoardStoreData(
    (state) => state.setCardPreviewHtml
  );
  const setCardDownloadHtml = onBoardStoreData(
    (state) => state.setCardDownloadHtml
  );
  const setDrImgStatus = onBoardStoreData((state) => state.setDrImgStatus);
  const setSpouseStatus = onBoardStoreData((state) => state.setSpouseStatus);
  const formLang = onBoardStoreData((state) => state.formLang);
  const [selectedLanguage, setSelectedLanguage] = useState(formLang);
  const [isOpen, setIsOpen] = useState(false);
  const setFestiveCardType = onBoardStoreData(
    (state) => state.setFestiveCardType
  );
  const setBrandSpeciality = onBoardStoreData(
    (state) => state.setBrandSpeciality
  );

  const setDoctorImg = onBoardStoreData((state) => state.setDoctorImg);
  const [showDrSearch, setShowDrSearch] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const setDoctorTag = onBoardStoreData((state) => state.setDoctorTag);

  useEffect(() => {
    setDoctorImg();
  }, []);

  const doctorDetails = {
    first_name: "Nidhi",
    last_name: "Patel",
    clinic_name: "Medisage Clinics",
    address:
      "Lodha Supremus, 1403, Saki Vihar Rd, Tunga Village, Chandivali, Powai, Mumbai, Maharashtra 400072, India",
  };

  useEffect(() => {
    clearSlots();
    setDoctorData([]);
    setDrImgStatus(false);
    setSpouseStatus(false);
    setDoctorTag("Dr.");
  }, []);

  useEffect(() => {
    const openGooglePermission = () => {
      if (window?.android_bridge) {
        window?.android_bridge?.deviceLocationPermission(true);
      } else if (window?.webkit) {
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "deviceLocationPermission",
          data: true,
        });
      }
    };
    openGooglePermission();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      setLoading(true);
      try {
        const response = await getRequest({
          url: `${getCampaignPostData}/${id}?nopagination`,
        });
        const data = response?.data;
        if (data?.status) {
          setLoading(false);
          setPosterData(data?.response?.data);
        } else {
          setLoading(false);
          toast.error("Failed to fetch posters");
        }
      } catch (error) {
        if (error?.status === 401) {
          localStorage.removeItem("token", "");
        }
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };
    fetchImage();
  }, [id]);

  useEffect(() => {
    console.log(campaignId, "campaignId");
    const fetchJournalContent = async () => {
      setJournalData([]);
      setNoData(false);
      setLoading(true);
      try {
        const response = await getRequest({
          url: `${journalContent}?type=${
            campaignId == 6 ? "journal" : campaignId == 7 ? "ecg" : ""
          }`,
        });
        const data = response?.data;
        if (data?.status) {
          setLoading(false);
          console.log(data.response);
          if (data?.response?.data?.length > 0) {
            setNoData(false);
            setJournalData(data?.response?.data);
          } else {
            setNoData(true);
            setJournalData([]);
          }
        } else {
          setNoData(false);
          setLoading(false);
          toast.error("Failed to fetch posters");
        }
      } catch (error) {
        console.log(error);
        if (error?.status === 401) {
          localStorage.removeItem("token", "");
          navigate("/");
        }
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };
    if (campaignId == 6 || campaignId == 7) fetchJournalContent();
  }, [campaignId]);

  // Language-specific card IDs
  const languages = [
    {
      value: "english",
      label: "English",
    },
    { value: "hindi", label: "Hindi" },
    { value: "marathi", label: "Marathi" },
  ];

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (lang) => {
    setSelectedLanguage(lang);
    setFormLang(lang); // Persist language selection
    setIsOpen(false); // Close the dropdown after selection
  };

  // Filter poster data based on selected language

  return (
    <div>
      <Header
        isBack={true}
        fromJournal={showDrSearch ? true : false}
        showSearch={showDrSearch ? true : false}
        setShowSharePopup={setShowSharePopup}
      />
      <div className="p-4 mt-16">
        {campaignId == 1 ||
        campaignId == 4 ||
        campaignId == 5 ||
        campaignId == 16 ||
        campaignId == 8 ? (
          <>
            <p className="font-semibold mb-4">
              {campaignId == 1
                ? "ECard List"
                : campaignId == 4
                ? "Medical Days List"
                : campaignId == 5
                ? "Chemist List"
                : campaignId == 16
                ? "Greeting List for Chemist"
                : campaignId == 8
                ? "Card List"
                : campaignId == 9
                ? "Anniversay List"
                : campaignId == 10
                ? "Birthday List"
                : "ECard List "}
            </p>
            {!isEmpty(posterData)
              ? posterData?.map((item, index) => {
                  return (
                    <Link
                      to={
                        campaignId == 5 || campaignId == 16
                          ? `${chemistForm}/${item?.id}`
                          : campaignId == 8
                          ? `${hqForm}/${item?.id}`
                          : `${clinic}/${item?.id}`
                      }
                      className="grid gap-2 mb-4"
                      key={index}
                    >
                      <img
                        onClick={() => {
                          setECardId(item?.id);
                          setFormLang("english");
                          setFestiveCardType("img");
                          setDrImgStatus(item?.dr_img_status);
                          setCardPreviewHtml(item?.preview_html_content);
                          setCardDownloadHtml(item?.download_html_content);
                          setSpouseStatus(item?.spouse_status);
                        }}
                        alt={item?.title}
                        src={item?.poster_image}
                        className="h-full w-full rounded-md shadow-md"
                      />
                    </Link>
                  );
                })
              : !loading && (
                  <>
                    <div className="grid justify-items-center items-center">
                      <img src="/noData.svg" className="h-64" alt="" />
                      <p className="font-semibold text-lg">No Data Found</p>
                    </div>
                  </>
                )}
          </>
        ) : campaignId == 2 || campaignId == 9 || campaignId == 10 ? (
          <>
            <p className="font-semibold mb-4">
              {campaignId == 2
                ? "Festive List"
                : campaignId == 9
                ? "Anniversay List"
                : campaignId == 10
                ? "Birthday List"
                : "ECard List "}
            </p>
            <div className=" flex justify-between items-center py-4 text-sm text-gray-700">
              <p className="text-sm">Select language of card</p>
              {/* Dropdown UI */}
              <div className="">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className=" flex justify-between items-center bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 "
                >
                  {
                    languages.find((lang) => lang.value === selectedLanguage)
                      ?.label
                  }
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 10l5 5 5-5H7z"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <div className="absolute mt-1 bg-white rounded-md shadow-lg z-10">
                    {languages.map((lang) => (
                      <button
                        key={lang.value}
                        onClick={() => handleSelect(lang.value)}
                        className={`block w-full text-left px-4 py-2 text-sm ${
                          selectedLanguage === lang.value
                            ? "bg-blue-500 text-white"
                            : "text-gray-900 hover:bg-blue-100"
                        }`}
                      >
                        {lang.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!isEmpty(posterData)
              ? posterData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link
                      to={`${clinic}/${item?.id}`}
                      className="grid gap-2 mb-4"
                    >
                      {item?.poster_image.includes(".mp4") ? (
                        <video
                          onClick={() => {
                            setECardId(item?.id);
                            setFestiveCardType("gif");
                            setFormLang(selectedLanguage);
                            setDrImgStatus(item?.dr_img_status);
                            setCardPreviewHtml(item?.preview_html_content);
                            setCardDownloadHtml(item?.download_html_content);
                            setSpouseStatus(item?.spouse_status);
                          }}
                          width="640"
                          height="360"
                          autoPlay
                          muted
                          loop
                        >
                          <source src={item?.poster_image} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          onClick={() => {
                            setECardId(item?.id);
                            setFestiveCardType("img");
                            setDrImgStatus(item?.dr_img_status);
                            setFormLang(selectedLanguage); // Set the selected language
                            setCardPreviewHtml(item?.preview_html_content);
                            setCardDownloadHtml(item?.download_html_content);
                            setSpouseStatus(item?.spouse_status);
                          }}
                          alt={item?.title}
                          src={item?.poster_image}
                          className="h-full w-full rounded-md shadow-md"
                        />
                      )}
                    </Link>
                  </React.Fragment>
                ))
              : !loading && (
                  <>
                    <div className="grid justify-items-center items-center">
                      <img src="/noData.svg" className="h-64" alt="" />
                      <p className="font-semibold text-lg">No Data Found</p>
                    </div>
                  </>
                )}
          </>
        ) : campaignId == 3 ? (
          <>
            <p className="font-semibold mb-4">Brand</p>
            {!isEmpty(posterData)
              ? posterData?.map((item, index) => {
                  return (
                    <Link
                      to={`${clinic}/${item?.id}`}
                      className="grid gap-2 mb-4"
                      key={index}
                    >
                      <img
                        onClick={() => {
                          setECardId(item?.id);
                          setFormLang("english");
                          setBrandSpeciality(item?.brand_speciality);
                          setFestiveCardType("img");
                          setCardPreviewHtml(item?.preview_html_content);
                          setCardDownloadHtml(item?.download_html_content);
                        }}
                        alt={item?.title}
                        src={item?.poster_image}
                        className="h-full w-full rounded-md shadow-md"
                      />
                    </Link>
                  );
                })
              : !loading && (
                  <>
                    <div className="grid justify-items-center items-center">
                      <img src="/noData.svg" className="h-64" alt="" />
                      <p className="font-semibold text-lg">No Data Found</p>
                    </div>
                  </>
                )}
          </>
        ) : campaignId == 6 || campaignId == 7 ? (
          <>
            <p className="mb-4 font-semibold">
              {campaignId == 6 ? "Journal List" : "ECG List"}
            </p>
            {!isEmpty(journalData) ? (
              journalData?.map((item, index) => {
                return (
                  <Journal1
                    item={item}
                    index={index}
                    setShowDrSearch={setShowDrSearch}
                  />
                );
              })
            ) : noData ? (
              <div className="grid justify-items-center items-center">
                <img src="/noData.svg" className="h-64" alt="" />
                <p className="font-semibold text-lg">No Data Found</p>
              </div>
            ) : null}
          </>
        ) : null}
        {loading && <Loader />}
      </div>
      {showSharePopup && (
        <SharePopup
          setShowSharePopup={setShowSharePopup}
          setShowDrSearch={setShowDrSearch}
        />
      )}
    </div>
  );
};

export default ECardList;
