import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../config/axiosInterceptor";
import { checkSurveyPassword, getQuestions } from "../config/apiEndpoints";
import Header from "./common/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./common/Loader";
import parse from "html-react-parser";
import { MdClose } from "react-icons/md";
import { surveyQuestion } from "../config/pathConstant";

const websiteUrl = "https://dev-fieldrep.mymedisage.com";

const Landing = () => {
  const param = useParams();
  const [surveyData, setSurveyData] = useState();
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isSurveydone, setIsSurveyDone] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("member_id");

  useEffect(() => {
    if (surveyData?.show_survey) {
      if (!surveyData?.is_survey_done) {
        setIsSurveyDone(false);
        // setPopup(false);
      } else {
        setIsSurveyDone(true);
        setPopup(false);
      }
    } else {
      setIsSurveyDone(true);
      setPopup(false);
    }
  }, []);

  useEffect(() => {
    const fetchSurvey = async () => {
      setLoader(true);
      try {
        const request = await getRequest({
          url: `${getQuestions}/${param?.id}?member_id=${param?.memberId}`,
        });
        const response = request?.data;
        if (response?.status) {
          setSurveyData(response?.response);
        }
      } catch (err) {
        console.log(err);
        if (err?.status === 401) {
          localStorage.removeItem("token", "");
        }
        toast.error("Something went wrong");
      }
      setLoader(false);
    };
    fetchSurvey();
  }, []);

  useEffect(() => {
    if (surveyData?.is_password) setPopup(true);
    else setPopup(false);
  }, [surveyData]);

  const onChangePassword = (e) => {
    let p = e.target.value;
    setPassword(p);
  };

  const attempt = async (id) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("password", password);
    try {
      const request = await postRequest({
        url: `${checkSurveyPassword}/${id}?member_id=${param?.memberId}`,
        params: ``,
        auth: true,
        body: formData,
      });
      if (request.status) {
        setLoader(false);
        setPopup(false);
        if (surveyData?.google_form_link && !surveyData?.html_content) {
          navigate(surveyData?.google_form_link);
        }
      }
    } catch (err) {
      if (err.response.status === 403) {
        setLoader(false);
        toast.error("Please enter correct password!!");
      } else throw new Error(err);
      console.error(err);
      setLoader(false);
    }
  };

  return (
    <>
      <Header isBack={true} />
      {loader && <Loader />}
      <div className="mt-16">
        {surveyData?.html_content && (
          <div className="p-4">{parse(surveyData?.html_content)}</div>
        )}
        {popup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded-lg relative max-w-sm shadow-lg w-[calc(100vw-32px)]">
              <MdClose
                className="text-xl text-gray-500 absolute top-3 right-3 cursor-pointer"
                onClick={() => setPopup(false)}
              />
              <label className="block text-gray-700">Enter Password</label>
              <input
                type="password"
                onChange={(e) => onChangePassword(e)}
                className="mt-2 border border-gray-300 p-2 rounded-lg w-full"
              />
              <div className="flex justify-end">
                <button
                  className="bg-blue-500 p-2 rounded-lg text-white mt-4 flex items-center justify-center"
                  onClick={() => attempt(surveyData?.id)}
                  disabled={!password.trim()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {isSurveydone ? (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-5 rounded-lg grid md:w-96 w-[calc(100vw-32px)] relative">
              {surveyData?.show_survey ? (
                <MdClose
                  className="absolute top-3 right-3 text-xl text-gray-600"
                  onClick={() => setIsSurveyDone(false)}
                />
              ) : null}
              <div className="grid gap-5 justify-items-center">
                <div className="text-4xl font-bold text-blue-900">Oops!</div>
                <img src="/survey_permission.svg" className="h-32 w-32" />
                {surveyData?.show_survey && surveyData?.is_survey_done ? (
                  <label className="text-center font-semibold">
                    It looks like you have already submitted this survey.
                  </label>
                ) : !surveyData?.show_survey ? (
                  <label className="text-center font-semibold">
                    You don&apos;t have access to attend this survey.
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {surveyData?.html_content ? (
          <div className="flex justify-center items-center my-4">
            {(surveyData?.show_survey && surveyData?.is_survey_done) ||
            !surveyData?.show_survey ? (
              <div className="flex justify-center items-center my-4">
                <button
                  className="bg-blue-500 p-2 rounded-lg text-white"
                  onClick={() => setIsSurveyDone(true)}
                >
                  Start
                </button>
              </div>
            ) : surveyData?.google_form_link ? (
              <Link to={surveyData?.google_form_link}>
                <button className="bg-blue-500 p-2 rounded-lg text-white">
                  Start
                </button>
              </Link>
            ) : (
              <Link
                to={
                  surveyData?.id
                    ? `${websiteUrl}/${surveyQuestion}/${
                        surveyData?.slug
                          ? surveyData?.slug
                          : surveyData?.id
                          ? surveyData?.id
                          : null
                      }/${param1}`
                    : "#"
                }
              >
                <button className="bg-blue-500 p-2 rounded-lg text-white">
                  Start
                </button>
              </Link>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Landing;
