import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import CaseCard from "./common/CaseCard";
import { getRequest } from "../config/axiosInterceptor";
import { getContentType } from "../config/apiEndpoints";
import isEmpty from "lodash";
import Loader from "./common/Loader";

const ContentType = () => {
  const [activeTab, setActiveTab] = useState("case");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const request = await getRequest({
          url: `${getContentType}?content_type=${activeTab}&page=1`,
        });
        const response = request?.data;
        const data = response?.response;
        setData(data?.data);
        setLoader(false);
      } catch (err) {
        if (err?.status === 401) {
          localStorage.removeItem("token", "");
        }
        setLoader(false);
        console.log(err);
      }
      setLoader(false);
    };
    if (activeTab) fetchData(activeTab);
  }, [activeTab]);

  return (
    <div className="">
      <Header isBack={true} />
      <div className="p-4 mt-16">
        <div className="grid grid-cols-3 gap-3">
          <div
            className={`grid justify-items-center gap-1 p-2 rounded-md ${
              activeTab == "case" ? "border border-blue-800" : ""
            }`}
            onClick={() => setActiveTab("case")}
          >
            <img className="h-8 w-8" src="case.svg" />
            <p className="text-xs text-gray-600">Cases</p>
          </div>
          <div
            className={`grid justify-items-center gap-1 p-2 rounded-md ${
              activeTab == "video" ? "border border-blue-800" : ""
            }`}
            onClick={() => setActiveTab("video")}
          >
            <img className="h-8 w-8" src="video.svg" />
            <p className="text-xs text-gray-600">Videos</p>
          </div>
          <div
            className={`grid justify-items-center gap-1 p-2 rounded-md ${
              activeTab == "article" ? "border border-blue-800" : ""
            }`}
            onClick={() => setActiveTab("post")}
          >
            <img className="h-8 w-8" src="article.svg" />
            <p className="text-xs text-gray-600">Post</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="grid gap-4">
          {isEmpty(data) &&
            data?.map((item, index) => {
              return <CaseCard data={item} key={index} />;
            })}
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default ContentType;
