import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { addClinics, getExtraDetails } from "../config/apiEndpoints";
import { postRequest, putRequest } from "../config/axiosInterceptor";
import { onBoardStoreData } from "../store/onboardStore";
import Loader from "./common/Loader";

const EditDoctor = ({ setShowDoctorPopup, queryType, docId }) => {
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      whatsappNumber: doctorData?.whatsapp_number?.includes("91")
        ? doctorData?.whatsapp_number.replace("91", "").slice(-10)
        : doctorData?.whatsapp_number ?? null,
      marriageDate: doctorData?.marriage_date ?? null,
      clinicOpeningDate: doctorData?.opening_date ?? null,
      dateOfBirth: doctorData?.date_of_birth ?? null,
    },
  });

  console.log(doctorData);

  useEffect(() => {
    if (!isEmpty(doctorData)) {
      setValue(
        "whatsappNumber",
        doctorData?.whatsapp_number?.includes("91")
          ? doctorData?.whatsapp_number.replace("91", "").slice(-10)
          : doctorData?.whatsapp_number ?? null
      );
      setValue("marriageDate", doctorData?.marriage_date);
      setValue("clinicOpeningDate", doctorData?.opening_date);
      setValue("dateOfBirth", doctorData?.date_of_birth);
    }
  }, [doctorData]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "opening_date",
      data?.clinicOpeningDate
        ? data?.clinicOpeningDate
        : doctorData?.opening_date
        ? doctorData?.opening_date
        : ""
    );
    formData.append(
      "date_of_birth",
      data?.dateOfBirth
        ? data?.dateOfBirth
        : doctorData?.date_of_birth
        ? doctorData?.date_of_birth
        : ""
    );
    formData.append(
      "marriage_date",
      data?.marriageDate
        ? data?.marriageDate
        : doctorData?.marriage_date
        ? doctorData?.marriage_date
        : ""
    );
    formData.append(
      "whatsapp_number",
      data?.whatsappNumber
        ? data?.whatsappNumber
        : doctorData?.whatsapp_number
        ? doctorData?.whatsapp_number
        : ""
    );
    formData.append(
      "map_id",
      doctorData?.map_id ? doctorData?.map_id : docId ? docId : ""
    );
    formData.append("map_type", queryType);
    try {
      const request = await postRequest({
        url: getExtraDetails,
        body: formData,
      });
      const response = request?.data;
      if (response?.status) {
        setDoctorData(response?.response);
        setLoading(false);
        setShowDoctorPopup(false);
        toast.success("Details Updated Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
        setShowDoctorPopup(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
      console.error("Error submitting form", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 z-50 overflow-scroll">
        <div className="bg-white w-11/12 max-w-lg p-4 rounded-lg shadow-lg relative">
          <div className="flex justify-between items-center">
            <h2 className="text-base font-semibold text-gray-800">
              Edit {queryType === "chemist" ? "Chemist" : "Doctor"} Details
            </h2>
            <MdClose
              className="text-xl text-gray-600 cursor-pointer"
              onClick={() => {
                setShowDoctorPopup(false);
              }}
            />
          </div>
          <hr className="my-3" />
          <form onSubmit={handleSubmit(onSubmit)} className="grid gap-2">
            <div className="grid gap-1">
              <label className="text-sm" htmlFor="whatsappNumber">
                Whatsapp No.
              </label>
              <input
                id="whatsappNumber"
                type="number"
                placeholder="Enter Whatsapp No."
                className="text-sm border border-gray-200 p-2 rounded-md focus:outline-none"
                {...register("whatsappNumber", {
                  required: false,
                })}
              />
            </div>
            <div className="grid gap-1">
              <label className="text-sm" htmlFor="marriageDate">
                Marriage Date
              </label>
              <input
                id="marriageDate"
                type="date"
                placeholder="Enter Marriage Date"
                className="text-sm border border-gray-200 p-2 rounded-md focus:outline-none w-full"
                {...register("marriageDate", {
                  required: false,
                })}
              />
            </div>
            <div className="grid gap-1">
              <label className="text-sm" htmlFor="clinicOpeningDate">
                {queryType === "chemist" ? "Chemist" : "Clinic"} Opening Date
              </label>
              <input
                id="clinicOpeningDate"
                type="date"
                placeholder="Enter Clinic Opening Date"
                className={`text-sm border p-2 rounded-md focus:outline-none w-full
                  ${
                    errors.clinicOpeningDate
                      ? "border-red-500"
                      : "border-gray-300"
                  }
                  `}
                {...register("clinicOpeningDate", {
                  required: true,
                })}
              />
              {errors.clinicOpeningDate && (
                <p className="text-xs font-medium text-red-500">
                  Clinic Opening Date is required
                </p>
              )}
            </div>
            <div className="grid gap-1">
              <label className="text-sm" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <input
                id="dateOfBirth"
                type="date"
                placeholder="Enter Date of Birth"
                className={`text-sm border p-2 rounded-md focus:outline-none w-full
                   ${errors.dateOfBirth ? "border-red-500" : "border-gray-300"}
                  `}
                {...register("dateOfBirth", {
                  required: true,
                })}
              />
              {errors.dateOfBirth && (
                <p className="text-xs font-medium text-red-500">
                  Date of Birth is required
                </p>
              )}
            </div>
            <div className="place-self-end" type="submit">
              <button className="text-white bg-blue-500 rounded-lg px-3 py-1.5 mt-2 text-sm">
                Update
              </button>
            </div>
          </form>
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default EditDoctor;
